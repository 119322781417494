@use '../../../Components/Theme/theme';

.division01 {
  padding: 5%;
  position: relative;
  background-image: linear-gradient(#0058D0, #0058D0);
  clip-path: polygon(
    0 0,
    100% 0,
    100% 100%,
    0 calc(100% - 7vw)
  );
  padding-bottom: 15%;
}

.generalContainer {
  background: #F2F2F2;
}

.titlePage {
  font-family: theme.$main-font;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 140%;
  display: flex;
  justify-content: center;
  color: #FFFFFF;
  margin-bottom: 63px;
  margin-top: 50px;
}

.emphasis {
  cursor: pointer;
  .rowColumn {
    margin-top: 32px;
    div {
      font-family: theme.$main-font;
      font-style: normal;
      font-weight: 600;
      font-size: 28px;
      line-height: 110%;
      color: #FFFFFF;
      margin-bottom: 15px;
    }
    span {
      font-family: theme.$main-font;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 110%;
      color: #FFFFFF;
    }
  }
}

.titleCard {
  font-family: theme.$main-font;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 110%;
  color: #121212;
}

.nameAuthor {
  margin-top: 24px;
  font-family: theme.$main-font;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 110%;
  color: #121212;
  span {
    font-weight: 700;
  }
}

.dateCard {
  margin: 16px 0 16px;
  font-family: theme.$main-font;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 110%;
  color: #121212;
}

.descCard {
  font-family: theme.$main-font;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 110%;
  color: #CCCCCC;
}

.imageMessage {
  position: absolute;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-left: 280px;
  display: flex;
  width: 27.95px;
  height: 24.5px;
  margin-top: 19.75px;
}

.circleMessage {
  background-color: #FF0000;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  position: absolute;
  font-size: 8px;
  text-align: center;
  color: #FFFFFF;
  font-weight: 600;
  margin-top: 32px;
  margin-left: 300px;
}

.imageHeart {
  position: absolute;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-left: 340px;
  display: flex;
  width: 23.33px;
  height: 20.81px;
  margin-top: 19.75px;
}

.circleHeart {
  background-color: #FF0000;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  position: absolute;
  font-size: 8px;
  text-align: center;
  color: #FFFFFF;
  font-weight: 600;
  margin-top: 30px;
  margin-left: 355px;
}

.title01 {
  font-family: theme.$main-font;
  font-size: 28px;
  font-weight: 800;
  line-height: 31px;
  color: #FFCC00;
  font-style: normal;
  margin-bottom: 48px;
}

.subTitle01 {
  font-family: theme.$main-font;
  font-size: 36px;
  font-weight: 600;
  line-height: 50px;
  font-style: normal;
  color: #FFFFFF !important;
}

.btnDivision01 {
  margin-top: 64px;
}

.textImage {
  font-family: theme.$main-font;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 110%;
  color: #FFCC00;
  margin-bottom: 32px;
  display: flex;
}

.morePopular {
  flex-direction: column;
  .rowContent {
    flex-direction: row;
    display: flex;
    margin-bottom: 15px;
    cursor: pointer;
    img {
      width: 200px;
      height: 112.5px;
    }
    .rowColumn {
      margin-left: 32px;
      div {
        font-family: theme.$main-font;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 110%;
        color: #FFFFFF;
        margin-bottom: 15px;
      }
      span {
        font-family: theme.$main-font;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 110%;
        color: #FFFFFF;
      }
    }
  }
}
// *********************************************************************


.division02 {
  background-color: theme.$background-01;
  margin-bottom: 8%;
}

.divisionBlogNews02 {
  background-color: theme.$background-01;
  margin-bottom: -8%;
}

.boldText {
  margin-left: 5px;
  font-weight: 900;
}

.marginRight {
  margin-right: 5px;
}

.accordionButton:after{
  order: -1 !important;
  margin-left: 0 !important; 
  margin-right:0.5em !important;
}

.accordionButton:not(.collapse)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%232532d8'%3e%3cpath fill-rule='evenodd' stroke='blue' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordionButtonHeader:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%232532d8'%3e%3cpath fill-rule='evenodd' stroke='blue' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordionItemHeader {
  width: 90%;
  div {
    border-radius: 8px !important;
  }
  h2 {
    button {
      border-radius: 8px;
      width: 100%;
    }
  }
}

.accordionItem {
  margin-bottom: 12px;
}

.accordionButton:not(.collapsed) {
  color: #121212 !important;
  background-color: #fff !important;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}

.accordionButton{
  font-family: theme.$main-font;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
}

.accordionButtonHeader:not(.collapsed) {
  color: #121212 !important;
  background-color: #fff !important;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}

.accordionButtonHeader {
  font-family: theme.$main-font;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  color: #121212;
}

.title02 {
  font-family: theme.$main-font;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 110%;
  display: flex;
  color: #0058D0;
}

.title02Main {
  font-family: theme.$main-font;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 110%;
  display: flex;
  color: #0058D0;
  margin: -60px 0 72px 0;
}

.division3Rectangle {
  border: 2px solid #121212;
  justify-content: center;
  width: 274px;
  height: 248px;
  padding: 30px;
  border: 1.2px solid #FFCC00;
  border-radius: 8px;
  background-color: #FFCC00;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);
  img {
    width: 80px;
    height: 80px;
  }
}

.asterisk {
  color: #BA0006;
  margin-left: 5px;
}

.containerFoot {
  margin-top: 5%;
}

.groupInputBtn {
  display: flex;
  flex-direction: row;
}

.btnLoadMore {
  margin-top: 64px;
}

.textInpuSearch {
  border-top-color: transparent;
  background-color: #CCCCCC;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-top-right-radius: 1px;
  border-bottom-right-radius: 1px;
  height: 48px;
}

.subTextInpuSearch {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.btnSearch {
  margin-left: -7px;
  z-index: 1;
}

.btnSearchRet {
  background-color: #CCCCCC;
  width: 10px;
}

.textInpuSearch::placeholder {
  font-family: theme.$main-font;
  color: #A6A6A6;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  opacity: 1; /* Firefox */
}

.textInpuSearch:hover {
  border: none !important;
}

.textInpuSearch:focus-visible {
  background-color: #CCCCCC;
  z-index: 0;
}

.textInpuSearch:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  z-index: 0;
}

.textInput::placeholder {
  font-family: theme.$main-font;
  color: #A6A6A6;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  opacity: 1; /* Firefox */
}

.textInput {
  border-top-color: transparent;
  background: #FFFFFF;
  border-radius: 8px;
  height: 52px;
}

.squareText {
  font-family: theme.$main-font;
  font-size: 28px;
  line-height: 110%;
  font-weight: 700;
  color: #0058D0;
  font-style: normal;
  margin-top: 32px;
  margin-bottom: 16px;
}

.subSquareText {
  font-family: theme.$main-font;
  font-size: 16px;
  line-height: 100%;
  font-weight: 600;
  color: #121212;
  font-style: normal;
  margin-bottom: 32px;
}

.titleForm {
  font-family: theme.$main-font;
  color: theme.$blue-01;
  font-size: 28px;
  line-height: 110%;
  font-weight: 700;
  font-style: normal;
  margin-top: -140px;
  margin-bottom: 32px;
  position: absolute;
}

.titleFoot {
  font-family: theme.$main-font;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 110%;
  display: flex;
  color: #0058D0;
}

.formBtnSearch {
  margin: 0 0 72px;
}

.subTitleFoot {
  font-family: theme.$main-font;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 110%;
  display: flex;
  color: #121212;
  margin-top: 24px;
}

.formCheck:checked {
  background-color: #1BB243 !important;
  border-color: #1BB243 !important;
}

.checkForm {
  margin-top: 25px;
  flex-direction: row;
}

.textCheck {
  font-family: theme.$main-font;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  color: #121212;
}

.textLabel {
  font-family: theme.$main-font;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  color: #121212;
}
// *********************************************************************
.division03 {
  background-color: #F2F2F2;
  margin-bottom: -85px;
}

.division03ColorTop {
  margin-top: -215px !important;
  position: relative;
  background-image: linear-gradient(#FFCC00, #FFCC00);
  clip-path: polygon(
    0 0,
    100% 22.5%,
    100% 100%,
    0 calc(100%)
  );
  // padding-top: 130px;
  height: 600px;
}

.mouseHouver:hover {
  // box-shadow: -4px -4px 4px 4px rgba(0, 0, 0, 0.25), 4px 4px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 1;
  cursor: pointer;
}

.newsTitle {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 140%;
  color: #121212;
  margin-top: 48px;
}

.newsSubTitle {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 140%;
  color: #121212;
  margin-top: 48px;
}

.imageNewsBlog {
  height: 450px;
}

.containerImage {
  display: flex;
  flex-direction: column;
  margin-top: 48px;
}

.containerContent {
  // background-color: theme.$background-01;
  // background-color: #BA0006;
  margin-bottom: 8%;
  width: 800px;
  margin-top: 100px;
}

.cardBody {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 16px;
}

.btnComment {
  margin-right: 24px;
}

.containerBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.containerAuthor {
  display: flex;
  flex-direction: row;
  img {
    width: 64px;
    height: 64px;
    margin: 0 16px 0 16px;
  }
  div {
    display: flex;
    flex-direction: column;
    font-family: theme.$main-font;
    font-style: normal;
    span:nth-child(1) {
      font-weight: 700;
      font-size: 18px;
      line-height: 20px;
      color: #121212;
    }
    span:nth-child(2) {
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      color: #0058D0;
    }
    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      span:nth-child(1) {
        width: 5px;
        height: 5px;
        background-color: #757575;
        border-radius: 3px;
        margin-right: 8px;
      }
      span:nth-child(2) {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #757575;
      }
    }
  }
}

.imageMessageNewsBlog {
  position: absolute;
  justify-content: center;
  align-content: center;
  align-items: center;
  display: flex;
  width: 27.95px;
  height: 24.5px;
  margin-top: 19.75px;
  margin-left: 690px;
}

.circleMessageNewsBlog {
  background-color: #FF0000;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  position: absolute;
  font-size: 8px;
  text-align: center;
  color: #FFFFFF;
  font-weight: 600;
  margin-top: 32px;
  margin-left: 710px;
}

.imageHeartNewsBlog {
  position: absolute;
  justify-content: center;
  align-content: center;
  align-items: center;
  display: flex;
  width: 23.33px;
  height: 20.81px;
  margin-top: 19.75px;
  margin-left: 735px;
}

.circleHeartNewsBlog {
  background-color: #FF0000;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  position: absolute;
  font-size: 8px;
  text-align: center;
  color: #FFFFFF;
  font-weight: 600;
  margin-top: 30px;
  margin-left: 750px;
}

.containerText {
  font-family: theme.$main-font;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  color: #121212;
  margin-top: 48px;
}

.lineDivider {
  border: 1px solid #121212;
  margin: 48px 0 48px;
}

.textComments {
  font-family: theme.$main-font;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 110%;
  color: #0058D0;
}

.containerComments {
  font-family: theme.$main-font;
  display: flex;
  flex-direction: row;
  div:first-child {
    width: 40px;
    height: 40px;
    background: #FFCC00;
    border-radius: 20px;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 110%;
    color: #0058D0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 16px 0 29px;
  }

  .newColor {
    width: 40px;
    height: 40px;
    background: #0058D0 !important;
    border-radius: 20px;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 110%;
    color: #FFCC00 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 16px 0 29px;
  }

  div {
    font-family: theme.$main-font;
    span:nth-child(1) {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 20px;
      color: #121212;
    }

    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 24px;
      span:nth-child(1) {
        width: 5px;
        height: 5px;
        background-color: #757575;
        border-radius: 3px;
        margin-right: 8px;
      }
      span:nth-child(2) {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #757575;
      }
    }
  }
}

.titleNewsBlog {
  font-family: theme.$main-font;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 110%;
  color: #0058D0;
}

.textEmailAddress {
  display: flex;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  color: #121212;
  margin-top: 16px;
}

.textLabel {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  color: #121212;
}

.inputText {
  input {
    width: 392px;
    height: 52px;
    background: #FFFFFF;
    border: 1px solid #A6A6A6;
    border-radius: 8px;
  }
  ::placeholder {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    color: #A6A6A6;
  }
}

.inputTextEmail {
  margin-left: 16px;
  input {
    width: 392px;
    height: 52px;
    background: #FFFFFF;
    border: 1px solid #A6A6A6;
    border-radius: 8px;
  }
  ::placeholder {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    color: #A6A6A6;
  }
}

.textarea {
  textarea {
    width: 800px;
    height: 95px;
    background: #FFFFFF;
    border: 1px solid #BDBDBD;
    border-radius: 8px;
    resize: none;
  }
  ::placeholder {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #757575;
  }
}

.containerInput {
  display: flex;
  justify-content: space-between;
}

.containerBtnComment {
  display: flex;
  justify-content: flex-end;
  width: 800px;
  margin-top: 32px;
}

.asterisk {
  color: #BA0006;
  margin-left: 5px;
}

.containerForm {
  margin-top: 32px;
}

.containerButton {
  display: flex;
  flex-direction: column;
  margin-top: -80px !important;
}


// **************************************************************************************************************************
// Breakpoints
// @media (min-width: 992px) {
//   .deviceDivision01_02 {
//     display: none !important;
//     visibility: hidden !important;
//   }
// }

// @media (max-width: 991.98px) {
//   .title01 {
//     text-align: left !important;
//   }
//   .subTitle01 {
//     text-align: left !important;
//   }
//   .deviceDivision01 {
//     display: none !important;
//     visibility: hidden !important;
//   }
//   .btnDivision01 {
//     margin-top: -100px !important;
//   }

//   .title02 {
//     text-align: left !important;
//   }
// }