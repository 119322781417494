.division01 {
  padding: 5%;
  position: relative;
  background-image: linear-gradient(#0058D0, #0058D0);
  clip-path: polygon(
    0 0,
    100% 0,
    100% 100%,
    0 calc(100% - 5vw)
  );
  margin-top: 65px;
}

.imageDivision01 {
  width: 777px; 
  height: 566px;
}

.title01 {
  font-family: 'Poppins' !important;
  font-size: 28px;
  font-weight: 800;
  line-height: 31px;
  color: #FFCC00;
  font-style: normal;
  margin-bottom: 48px;
}

.subTitle01 {
  font-family: 'Poppins' !important;
  font-size: 36px;
  font-weight: 600;
  line-height: 50px;
  font-style: normal;
  color: #FFFFFF !important;
}

.btnDivision01 {
  margin-top: 64px;
}
// *********************************************************************
.division02 {
  background-color: #FFFFFF !important;
}

.title02 {
  font-family: 'Poppins' !important;
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  color: #0058D0;
  font-style: normal;
  margin-bottom: 72px;
}

.subText02 {
  font-family: 'Poppins' !important;
  font-size: 28px;
  font-weight: 700;
  line-height: 31px;
  color: #121212;
  font-style: normal;
  margin-bottom: 32px;
  margin-top: 24px;
}

.TextDefaultDivision02 {
  font-family: 'Poppins' !important;
  font-size: 18px;
  line-height: 20px;
  font-weight: 400;
  font-style: normal;
  text-align: left;
  color: #121212;
}

.Text03Division02 {
  font-family: 'Poppins' !important;
  font-size: 28px;
  line-height: 31px;
  font-weight: 700;
  font-style: normal;
  text-align: left;
  color: #FF6600;
  margin-right: 10px;
}

.border01Division2 {
  border: 2px solid #121212;
  justify-content: center;
  height: 316px;
  padding: 30px;
  border: 1.2px solid #299BFF;
  border-radius: 8px;
}

.border02Division2 {
  border: 2px solid #121212;
  justify-content: center;
  height: 316px;
  padding: 30px;
  border: 1.2px solid #FF6600;
  border-radius: 8px;
}

.border03Division2 {
  border: 2px solid #121212;
  justify-content: center;
  height: 316px;
  padding: 30px;
  border: 1.2px solid #FFCC00;
  border-radius: 8px;
}
// *********************************************************************
.division03 {
  background-color: #F2F2F2;
}

.title03 {
  font-family: 'Poppins' !important;
  font-size: 28px;
  font-weight: 700;
  line-height: 34px;
  font-style: normal;
  color: #121212;
  margin-bottom: 55px;
  span {
    color: #0058D0;
  }
}

.subTitle03 {
  font-family: 'Poppins' !important;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  font-style: normal;
  align-items: center;
  text-align: center;
  color: #121212;
  margin: 20px 72px 48px;
}
// *********************************************************************
.imageCourse2 {
  margin-top: 72px;
  img {
    width: 100%;
    height: 100%;
  }
}

// *********************************************************************
.division04 {
  background-color: #F2F2F2;
  margin-bottom: -85px;
}

.title04 {
  font-family: 'Poppins' !important;
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  color: #0058D0;
  font-style: normal;
  margin-bottom: 72px;
  margin-top: 30px;
}

.subTitle04 {
  font-family: 'Poppins' !important;
  font-size: 18px;
  line-height: 29px;
  font-weight: 400;
  font-style: normal;
  text-align: left;
  color: #121212;
}

.btnDivision04 {
  margin-top: 72px;
}

// *********************************************************************
.division05 {
  background-color: #F2F2F2;
  margin-top: -60px;
}

.title05 {
  font-family: 'Poppins' !important;
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  color: #0058D0;
  font-style: normal;
  margin-bottom: 72px;
}

.subTitle05 {
  font-family: 'Poppins' !important;
  font-size: 18px;
  line-height: 29px;
  font-weight: 400;
  font-style: normal;
  text-align: left;
  color: #121212;
}

.strongDivision05 {
  font-family: 'Poppins' !important;
  font-size: 18px;
  line-height: 29px;
  font-weight: 700 !important;
  font-style: normal;
  text-align: left;
  color: #121212;
}
.deviceDivision05 {
  margin-top: -40px;
  // display: flex;
}
// *********************************************************************
.division06 {
  background-color: #F2F2F2;
  margin-bottom: -85px;
}

.title06 {
  font-family: 'Poppins' !important;
  font-size: 28px;
  font-weight: 700;
  line-height: 31px;
  color: #FFCC00;
  font-style: normal;
  text-align: center;
  margin-bottom: 24px;
}

.subTitle06 {
  font-family: 'Poppins' !important;
  font-size: 36px;
  font-weight: 600;
  line-height: 40px;
  font-style: normal;
  text-align: left;
  color: #FFFFFF;
  text-align: center;
  margin-bottom: 42px;
}

.division06ColorTop {
  margin-top: -210px !important;
  position: relative;
  background-image: linear-gradient(#0058D0, #0058D0);
  clip-path: polygon(
    0 0,
    100% 25%,
    100% 100%,
    0 calc(100%)
  );
}

// **************************************************************************************************************************
// Breakpoints
@media (min-width: 992px) {
  .deviceDivision01_02 {
    display: none !important;
    visibility: hidden !important;
  }

  .deviceDivision03_01 {
    display: none !important;
    visibility: hidden !important;
  }

  .deviceDivision05_01 {
    display: none !important;
    visibility: hidden !important;
  }

  .deviceDivision04_01 {
    display: none !important;
    visibility: hidden !important;
  }
}

@media (max-width: 991.98px) {
  .title01 {
    text-align: left !important;
  }
  .subTitle01 {
    text-align: left !important;
  }
  .deviceDivision01 {
    display: none !important;
    visibility: hidden !important;
  }
  .btnDivision01 {
    margin-top: -100px !important;
  }

  .title02 {
    text-align: left !important;
  }

  .title03 {
    text-align: left !important;
  }
  .subTitle03 {
    margin-bottom: -50px !important;
  }
  .deviceDivision03 {
    display: none !important;
    visibility: hidden !important;
  }

  .division05 {
    margin-bottom: -100px !important;
  }
  .title05 {
    margin-top: -80px !important;
    text-align: left !important;
  }
  .deviceDivision05 {
    display: none !important;
    visibility: hidden !important;
  }
  .deviceDivision05_01 {
    margin-bottom: 32px !important;
  }

  .deviceDivision04 {
    display: none !important;
    visibility: hidden !important;
  }
  .deviceDivision04_01 {
    margin-bottom: 32px !important;
  }
}