.division01 {
  position: relative;
  background-image: linear-gradient(#0058D0, #0058D0);
  clip-path: polygon(
    0 0,
    100% 0,
    100% 100%,
    0 calc(100% - 5vw)
  );
}

.title01 {
  font-family: 'Poppins' !important;
  font-size: 28px;
  font-weight: 700;
  line-height: 31px;
  color: #FFCC00;
  font-style: normal;
  margin-bottom: 48px;
}

.subTitle01 {
  font-family: 'Poppins' !important;
  font-size: 36px;
  font-weight: 600;
  line-height: 50px;
  font-style: normal;
  color: #FFFFFF !important;
}

.btnDivision01 {
  margin-top: 64px;
}

.division02 {
  background-color: #FFFFFF !important;
}

.subContainer {
  margin-bottom: -50px;
}

.title02 {
  font-family: 'Poppins' !important;
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  color: #0058D0;
  font-style: normal;
  margin-top: -50px;
  margin-left: 45px;
}

.cardYourBrand {
  // margin-left: 45px;
}

.division03 {
  background-color: #f2f2f2;
}

.title03 {
  font-family: 'Poppins' !important;
  font-size: 28px;
  font-weight: 700;
  line-height: 34px;
  font-style: normal;
  color: #121212;
  margin-bottom: 48px;
  span {
    color: #0058D0;
  }
}

.subTitle03 {
  font-family: 'Poppins' !important;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  font-style: normal;
  align-items: center;
  text-align: center;
  color: #121212;
  margin: 48px 72px;
}

.imageCourse2 {
  margin-top: 72px;
  img {
    width: 100%;
    height: 100%;
  }
}

.division04 {
  background-color: #F2F2F2;
}

.title04 {
  font-family: 'Poppins' !important;
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  color: #0058D0;
  font-style: normal;
  margin-bottom: 48px;
}

.subTitle04 {
  font-family: 'Poppins' !important;
  font-size: 18px;
  font-weight: 400;
  line-height: 29px;
  font-style: normal;
  text-align: left;
  color: #121212;
  margin-bottom: 72px;
}

.division05 {
  background-color: #F2F2F2;
  padding-bottom: 10px;
}

.title05 {
  font-family: 'Poppins' !important;
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  color: #0058D0;
  font-style: normal;
  margin-bottom: 48px;
}

.subTitle05 {
  font-family: 'Poppins' !important;
  font-size: 18px;
  font-weight: 400;
  line-height: 29px;
  font-style: normal;
  text-align: left;
  color: #121212;
  margin-bottom: 72px;
}

.containerDivision05 {
  margin-top: 100px !important;
}

.title06 {
  font-family: 'Poppins' !important;
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  color: #0058D0;
  font-style: normal;
  margin-bottom: 48px;
  margin-top: 100px;
}

.subTitle06 {
  font-family: 'Poppins' !important;
  font-size: 18px;
  font-weight: 400;
  line-height: 29px;
  font-style: normal;
  text-align: left;
  color: #121212;
  margin-bottom: 72px;
}

.division06 {
  background-color: #F2F2F2;
}

.container06ColorTop {
  margin-top: -150px !important;
  position: relative;
  background-image: linear-gradient(#FFCC00, #FFCC00);
  clip-path: polygon(
    0 0,
    100% 25%,
    100% 100%,
    0 calc(100%)
  );
}

.container06ColorTop_02 {
  flex-direction: row !important;
  display: flex;
}

.imageDivision06 {
  width: 660px;
  height: 454.67px;
  margin-right: 60px;
}

.division07 {
  background-color: #0058D0;
  margin-top: -80px;
  margin-bottom: -80px;
}

.title07 {
  font-family: 'Poppins' !important;
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  color: #FFCC00;
  font-style: normal;
  margin-bottom: 48px;
}

.subTitle07 {
  font-family: 'Poppins' !important;
  font-size: 18px;
  font-weight: 400;
  line-height: 29px;
  font-style: normal;
  text-align: left;
  color: #FFFFFF;
  margin-bottom: 72px;
}


.carouselHome08 {
  background-color: #FFFFFF !important;
  border: 1.2px solid #A6A6A6;
  border-radius: 8px;
  margin: 10px;
}
.containerCard08 {
  background-color: #FFFFFF;
  margin-bottom: 50px;
}
.image08 {
  margin-right: 20px;
}
.text08 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 40px;
  color: #0058D0;
  text-align: left;
  margin-top: 32px;
  margin-bottom: 40px;
}
.title08 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #121212;
  text-align: left;
  margin-bottom: 14px;
}
.subTitle08 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #121212;
  text-align: left;
  margin-bottom: 14px;
}
.containerLeft08{
  justify-content: space-between;
}
.containerStar08{
  text-align: left;
}
.description08 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #121212;
  text-align: left;
  margin-top: 32px;
}



.division09 {
  background-color: #FFFFFF;
  margin-bottom: -85px;
}

.title09 {
  font-family: 'Poppins' !important;
  font-size: 28px;
  font-weight: 700;
  line-height: 31px;
  color: #FFCC00;
  font-style: normal;
  text-align: center;
  margin-bottom: 24px;
}

.subTitle09 {
  font-family: 'Poppins' !important;
  font-size: 36px;
  font-weight: 600;
  line-height: 40px;
  font-style: normal;
  text-align: left;
  color: #FFFFFF;
  text-align: center;
  margin-bottom: 42px;
}

.division09ColorTop {
  margin-top: -120px;
  position: relative;
  background-image: linear-gradient(#0058D0, #0058D0);
  clip-path: polygon(
    0 0,
    100% 25%,
    100% 100%,
    0 calc(100%)
  );
}

.yourBrandHere {
  width: 120px;
  height: 120px;
  box-sizing: border-box;
  border-radius: 60px;
  border: 1px dashed #A6A6A6;
  flex-direction: column;
}

.textBrand {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 80%;
  color: #121212;
  margin-bottom: 8px;
}

.textBrandHere {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 100%;
  color: #0058D0;
  letter-spacing: 0.1em;
}

// **************************************************************************************************************************
// Breakpoints
@media (min-width: 992px) {
  .deviceDivision01_02 {
    display: none !important;
    visibility: hidden !important;
  }

  .deviceDivision03_01 {
    display: none !important;
    visibility: hidden !important;
  }

  .deviceDivision04_01 {
    display: none !important;
    visibility: hidden !important;
  }

  .deviceDivision05_01 {
    display: none !important;
    visibility: hidden !important;
  }

  .deviceDivision06_01 {
    display: none !important;
    visibility: hidden !important;
  }

  .deviceDivision07_01 {
    display: none !important;
    visibility: hidden !important;
  }
}

@media (max-width: 991.98px) {
  .title01 {
    text-align: left !important;
  }
  .subTitle01 {
    text-align: left !important;
  }
  .deviceDivision01 {
    display: none !important;
    visibility: hidden !important;
  }
  .btnDivision01 {
    margin-top: -100px !important;
  }

  .title02 {
    text-align: left !important;
  }

  .title03 {
    text-align: left !important;
  }
  .subTitle03 {
    margin-bottom: -50px !important;
  }
  .deviceDivision03 {
    display: none !important;
    visibility: hidden !important;
  }

  .division04 {
    margin-bottom: -100px !important;
  }
  .title04 {
    margin-top: -80px !important;
    text-align: left !important;
  }
  .deviceDivision04 {
    display: none !important;
    visibility: hidden !important;
  }
  .deviceDivision04_01 {
    margin-bottom: 32px !important;
  }

  .title05 {
    text-align: left !important;
  }
  .deviceDivision05 {
    display: none !important;
    visibility: hidden !important;
  }
  .containerDivision05 {
    margin-top: -70px !important;
  }
  .subTitle05 {
    margin-bottom: 32px !important;
  }

  
  .container06ColorTop {
    margin-top: -120px !important;
    position: relative;
    background-image: linear-gradient(#FFCC00, #FFCC00);
    clip-path: polygon(
      0 0,
      100% 5%,
      100% 100%,
      0 calc(100%)
    );
  }
  .deviceDivision06 {
    display: none !important;
    visibility: hidden !important;
  }
  .title06 {
    margin-top: -60px !important;
    text-align: left !important;
  }
  .subTitle06 {
    margin-bottom: 20px !important;
  }
  .deviceDivision06_image {
    margin: -120px -20px 0 !important;
  }
  .deviceDivision06_btn {
    margin-bottom: -50px !important;
  }
  .container06ColorTop_02 {
    margin-top:0px !important;
  }

  .carouselHome08 {
    margin: 0;
  }

  .division07 {
    margin-bottom: 0;
  }
  .title07 {
    margin-top: -30px !important;
    text-align: left !important;
  }
  .deviceDivision07 {
    display: none !important;
    visibility: hidden !important;
  }
  .deviceDivision07_image {
    margin-top: -180px !important;
  }
}

@media (min-width: 1400px) {
  .title06 {
    margin-top: 0px !important;
  }
}