.background {
  background-color: #FFFFFF !important;
}

.imageLogo {
  width: 153px;
  height: 100%;
  cursor: pointer;
}

.imageIcon {
  width: 28.33px;
  height: 26.75px;
}

.containerIcon {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.containerIconProfile {
  align-items: center;
  border: 2px solid #121212;
  border-radius: 16px;
}

.imageProfile {
  width: 28px;
  height: 26px;
  border-radius: 16px;
}

.textIcon {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  color: #121212;
  margin-top: 6px;
}

.textProfile {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 10px;
  color: #121212;
  margin-top: 6px;
}

.divider {
  width: 20px;
}

.iconInput {
  width: 20px;
  height: 20px;
}

.textInput {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  border: none;
  background-color: #EBEBEB;
}

.textInput::placeholder {
  color: #121212;
  opacity: 1; /* Firefox */
}

.textInput:hover {
  border: none !important;
}

.textInput:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  background-color: #EBEBEB;
}

.inputGroupTop {
  height: 52px;
}

.inputGroupTop01 {
  height: 52px;
  background-color: #EBEBEB;
  border-radius: 8px 0px 0px 8px;
}

.inputGroupText {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.containerImageForm {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.formContainer {
  margin-left: 15px;
}

.groupInputBtn {
  background-color: #EBEBEB;
  display: flex;
  flex-direction: row;
}