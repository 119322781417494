
.title01 {
  font-family: 'Poppins' !important;
  font-size: 28px;
  font-weight: 700;
  line-height: 31px;
  color: #0058D0;
  font-style: normal;
  text-align: left;
  margin-top: 24px;
}

.section01 {
  background-color: #F5F5F5;
  min-height: 100vh
}

.section02 {
  margin-top: 48px;
}

.infoTitle {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #121212;
}