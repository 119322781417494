
.title01 {
  font-family: 'Poppins' !important;
  font-size: 28px;
  font-weight: 700;
  line-height: 31px;
  color: #0058D0;
  font-style: normal;
  text-align: left;
  margin-top: 24px;
}

.section01 {
  background-color: #F5F5F5;
  min-height: 100vh
}

.section02 {
  margin-top: 48px;
}

.textLabel {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #121212;
}

.asterisk {
  color: #BA0006;
  margin-left: 5px;
}

.iconInput {
  width: 32px;
  height: 32px;
}

.iconInputCalendar {
  width: 16px;
  height: 16px;
}

.inputGroupText {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #FFFFFF;
  border-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.textInput {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  border: none;
  color: #121212;
}

.textInputCalendar {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  border: none;
  color: #121212;

  div:first-child {
    button:nth-child(3) {
      svg {
        display: none;
        visibility: hidden;
      }
      background-image: url('../../../Assets/images/icon/image26.png');
      width: 16px;
      height: 16px;
      background-repeat: no-repeat;
      align-self: center;

    }
  }
}

.inputGroupCalendar {
  background: #FFFFFF;
  border: 1px solid #cfcfcf;
  border-radius: 6px;
  width: 480px;
  height: 52px;
  :focus {
    box-shadow: none;
  }

  div:nth-child(1) {
    div {
      box-shadow: none !important;
      border: none !important;
    }
  }
}

.inputGroup {
  background: #FFFFFF;
  border: 1px solid #cfcfcf;
  border-radius: 6px;
  width: 480px;
  height: 52px;
  :focus {
    box-shadow: none;
  }
}

.phoneInputGroup {
  background: #FFFFFF;
  border: 1px solid #cfcfcf;
  border-radius: 6px;
  width: 480px;
  height: 52px;
  
  input {
    border: none;
  }
  :focus {
    box-shadow: none;
    outline: 0;
  }
}

.containerPassport01 {
  border: 2px dashed #A6A6A6;
  padding: 10px;
  width: 480px;
}

.containerPassport02 {
  background-color: #FFFFFF;
  padding: 50px;
  cursor: pointer;
}

.textPassport01 {
  text-align: center;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #A6A6A6;
}

.textPassport02 {
  color: #BA0006;
  text-decoration-line: underline;
}

.radioAddress {
  background-color: #D9D9D9;
}

.formDropdown {
  background-image: url("data:image/svg+xml;charset=utf-8,<svg width='12' height='8' fill='none' xmlns='http:%2F%2Fwww.w3.org/2000/svg'><path d='M1 1l4 4 4-4' stroke='blue' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/></svg>");
  width: 480px;
  height: 52px;
  &:focus {
    box-shadow: none !important;
    border: 1px solid #cfcfcf;
  }
}

.smallerData {
  width: 224px !important;
  height: 52px;
  &:focus {
    box-shadow: none !important;
    border: 1px solid #cfcfcf;
  }
}

.formInputSelect {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 480px;
}

.inputControl {
  width: 480px;
  height: 52px;
  &:focus {
    box-shadow: none !important;
    border: 1px solid #cfcfcf;
  }
}

.formFile {
  // visibility: hidden;
  // display: none;
}