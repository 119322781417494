.imageIcons {
  width: 48px;
  height: 48px;
  cursor: pointer;
  margin-left: 16px;
}

.imageLogo {
  width: 171.19px;
  height: 48px;
  cursor: pointer;
}

.backgroundColor {
  background: #121212;
  height: 210px;
}

.containerImages {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.containerIcons {
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.containerLogo {
  display: flex;
  flex-direction: column;
  margin-left: 60px;
  span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    color: #FFFFFF;
    text-align: left;
    margin: 71px 0 8px 0;
  }
}

.spaceRight {
  margin-right: 360px;
  width: 260px;
  display: flex;
  margin: 71px 0 8px 0;
}

.text1 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 100%;
  color: #FFFFFF;
  margin: 24px 0 8px 0;
}

.text2 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  color: #FFFFFF;
}