
.title01 {
  font-family: 'Poppins' !important;
  font-size: 28px;
  font-weight: 700;
  line-height: 31px;
  color: #0058D0;
  font-style: normal;
  text-align: left;
  margin-top: 24px;
}

.section01 {
  background-color: #F5F5F5;
  height: 100vh;
}

.description {
  font-family: 'Poppins' !important;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  color: #0058D0;
  font-style: normal;
  text-align: left;
  margin-top: 24px;
  color: #121212;
}

.margeBtn {
  margin-top: 64px;
}