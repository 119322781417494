.division01 {
  position: relative;
  background-image: linear-gradient(#0058D0, #0058D0);
  clip-path: polygon(
    0 0,
    100% 0,
    100% 100%,
    0 calc(100% - 5vw)
  );
}

.title01 {
  font-family: 'Poppins' !important;
  font-size: 28px;
  font-weight: 800;
  line-height: 31px;
  color: #FFCC00;
  font-style: normal;
  margin-bottom: 48px;
}

.subTitle01 {
  font-family: 'Poppins' !important;
  font-size: 36px;
  font-weight: 600;
  line-height: 50px;
  font-style: normal;
  color: #FFFFFF !important;
}

.btnDivision01 {
  margin-top: 64px;
}
// *********************************************************************
.division02 {
  background-color: #FFFFFF !important;
}

.title02 {
  font-family: 'Poppins' !important;
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  color: #0058D0;
  font-style: normal;
  margin-bottom: 72px;
}

.containerText02 {
  margin-left: 32px;
}

.titleText02 {
  font-family: 'Poppins' !important;
  font-size: 26px;
  font-weight: 700;
  line-height: 42px;
  color: #BA0006;
  font-style: normal;
  margin-bottom: 32px;
}

.subText02 {
  font-family: 'Poppins' !important;
  font-size: 18px;
  font-weight: 400;
  line-height: 29px;
  color: #121212;
  font-style: normal;
  margin-bottom: 32px;
}
// *********************************************************************
.division03 {
  background-color: #F2F2F2;
}

.title03 {
  font-family: 'Poppins' !important;
  font-size: 28px;
  font-weight: 700;
  line-height: 34px;
  font-style: normal;
  color: #121212;
  margin-bottom: 0;
  span {
    color: #0058D0;
  }
}

.subTitle03 {
  font-family: 'Poppins' !important;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  font-style: normal;
  align-items: center;
  text-align: center;
  color: #121212;
  margin: 0 72px 48px;
}
// *********************************************************************
.imageCourse2 {
  margin-top: 72px;
  img {
    width: 100%;
    height: 100%;
  }
}
// *********************************************************************
.division04 {
  background-color: #F2F2F2;
}

.title04 {
  font-family: 'Poppins' !important;
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  color: #0058D0;
  font-style: normal;
  margin-bottom: 72px;
}

.subTitle04 {
  font-family: 'Poppins' !important;
  font-size: 40px;
  line-height: 79px;
  font-weight: 700;
  font-style: normal;
  text-align: left;
  color: #121212;
}

.TextDefaultDivision04 {
  font-family: 'Poppins' !important;
  font-size: 28px;
  line-height: 31px;
  font-weight: 700;
  font-style: normal;
  text-align: left;
  color: #121212;
}

.Text01Division04 {
  font-family: 'Poppins' !important;
  font-size: 28px;
  line-height: 31px;
  font-weight: 700;
  font-style: normal;
  text-align: left;
  color: #BA0006;
}

.Text02Division04 {
  font-family: 'Poppins' !important;
  font-size: 28px;
  line-height: 31px;
  font-weight: 700;
  font-style: normal;
  text-align: left;
  color: #299BFF;
  margin-right: 10px;
}

.Text03Division04 {
  font-family: 'Poppins' !important;
  font-size: 28px;
  line-height: 31px;
  font-weight: 700;
  font-style: normal;
  text-align: left;
  color: #FF6600;
  margin-right: 10px;
}

.Text04Division04 {
  font-family: 'Poppins' !important;
  font-size: 28px;
  line-height: 31px;
  font-weight: 700;
  font-style: normal;
  text-align: left;
  color: #FFCC00;
  margin-right: 10px;
}
// *********************************************************************
.division05 {
  background-color: #F2F2F2;
  margin-bottom: -85px;
}

.title05 {
  font-family: 'Poppins' !important;
  font-size: 28px;
  font-weight: 700;
  line-height: 31px;
  color: #FFCC00;
  font-style: normal;
  text-align: center;
  margin-bottom: 24px;
}

.subTitle05 {
  font-family: 'Poppins' !important;
  font-size: 36px;
  font-weight: 600;
  line-height: 40px;
  font-style: normal;
  text-align: left;
  color: #FFFFFF;
  text-align: center;
  margin-bottom: 42px;
}

.division05ColorTop {
  margin-top: -90px;
  position: relative;
  background-image: linear-gradient(#0058D0, #0058D0);
  clip-path: polygon(
    0 0,
    100% 25%,
    100% 100%,
    0 calc(100%)
  );
}

// **************************************************************************************************************************
// Breakpoints
@media (min-width: 992px) {
  .deviceDivision01_02 {
    display: none !important;
    visibility: hidden !important;
  }

  .deviceDivision03_01 {
    display: none !important;
    visibility: hidden !important;
  }
}

@media (max-width: 991.98px) {
  .title01 {
    text-align: left !important;
  }
  .subTitle01 {
    text-align: left !important;
  }
  .deviceDivision01 {
    display: none !important;
    visibility: hidden !important;
  }
  .btnDivision01 {
    margin-top: -100px !important;
  }

  .title02 {
    text-align: left !important;
  }

  .title03 {
    text-align: left !important;
  }
  .subTitle03 {
    margin-bottom: -50px !important;
  }
  .deviceDivision03 {
    display: none !important;
    visibility: hidden !important;
  }

  .division04 {
    margin-bottom: -100px !important;
  }
  .title04 {
    margin-top: -80px !important;
    text-align: left !important;
  }
}
