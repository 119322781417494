@use '../../../Components/Theme/theme';

.division01 {
  padding: 5%;
  position: relative;
  background-image: linear-gradient(#0058D0, #0058D0);
  clip-path: polygon(
    0 0,
    100% 0,
    100% 100%,
    0 calc(100% - 7vw)
  );
  margin-top: 65px;
}

.generalContainer {
  background: #F2F2F2;
}

.imageDivision01 {
  width: 600px; 
  height: 487.5px;
}

.title01 {
  font-family: theme.$main-font;
  font-size: 28px;
  font-weight: 800;
  line-height: 31px;
  color: #FFCC00;
  font-style: normal;
  margin-bottom: 48px;
}

.subTitle01 {
  font-family: theme.$main-font;
  font-size: 36px;
  font-weight: 600;
  line-height: 50px;
  font-style: normal;
  color: #FFFFFF !important;
}

.btnDivision01 {
  margin-top: 64px;
}
// *********************************************************************


.division02 {
  background-color: theme.$background-01;
  margin-bottom: 8%;
}

.boldText {
  margin-left: 5px;
  font-weight: 900;
}

.marginRight {
  margin-right: 5px;
}

.accordionButton:after{
  order: -1 !important;
  margin-left: 0 !important; 
  margin-right:0.5em !important;
}

.accordionButton:not(.collapse)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%232532d8'%3e%3cpath fill-rule='evenodd' stroke='blue' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordionButtonHeader:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%232532d8'%3e%3cpath fill-rule='evenodd' stroke='blue' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordionItemHeader {
  width: 90%;
  div {
    border-radius: 8px !important;
  }
  h2 {
    button {
      border-radius: 8px;
      width: 100%;
    }
  }
}

.accordionItem {
  margin-bottom: 12px;
}

.accordionButton:not(.collapsed) {
  color: #121212 !important;
  background-color: #fff !important;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}

.accordionButton{
  font-family: theme.$main-font;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
}

.accordionButtonHeader:not(.collapsed) {
  color: #121212 !important;
  background-color: #fff !important;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}

.accordionButtonHeader {
  font-family: theme.$main-font;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  color: #121212;
}

.title02 {
  font-family: theme.$main-font;
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  color: theme.$blue-01;
  font-style: normal;
  margin-bottom: 72px;
}

.division3Rectangle {
  border: 2px solid #121212;
  justify-content: center;
  width: 274px;
  height: 248px;
  padding: 30px;
  border: 1.2px solid #FFCC00;
  border-radius: 8px;
  background-color: #FFCC00;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);
  img {
    width: 80px;
    height: 80px;
  }
}

.asterisk {
  color: #BA0006;
  margin-left: 5px;
}

.textInput::placeholder {
  color: #A6A6A6;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  opacity: 1; /* Firefox */
}

.textInput {
  border-top-color: transparent;
  background: rgba(204, 204, 204, 0.6);
  border-radius: 8px;
  height: 52px;
}

.textTextArea {
  border-top-color: transparent;
  background: rgba(204, 204, 204, 0.6);
  border-radius: 8px;
  height: 96px;
  resize: none;
}

.squareText {
  font-family: theme.$main-font;
  font-size: 28px;
  line-height: 110%;
  font-weight: 700;
  color: #0058D0;
  font-style: normal;
  margin-top: 32px;
  margin-bottom: 16px;
}

.subSquareText {
  font-family: theme.$main-font;
  font-size: 16px;
  line-height: 100%;
  font-weight: 600;
  color: #121212;
  font-style: normal;
  margin-bottom: 32px;
}

.titleForm {
  font-family: theme.$main-font;
  color: theme.$blue-01;
  font-size: 28px;
  line-height: 110%;
  font-weight: 700;
  font-style: normal;
  margin-top: -140px;
  margin-bottom: 32px;
  position: absolute;
}

.form {
  margin-top: -80px;
}

.textLabel {
  font-family: theme.$main-font;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  color: #A6A6A6;
}
// *********************************************************************
.division03 {
  background-color: #F2F2F2;
  margin-bottom: -85px;
}

.division03ColorTop {
  margin-top: -210px !important;
  position: relative;
  background-image: linear-gradient(#0058D0, #0058D0);
  clip-path: polygon(
    0 0,
    100% 10%,
    100% 100%,
    0 calc(100%)
  );
}

// **************************************************************************************************************************
// Breakpoints
@media (min-width: 992px) {
  .deviceDivision01_02 {
    display: none !important;
    visibility: hidden !important;
  }
}

@media (max-width: 991.98px) {
  .title01 {
    text-align: left !important;
  }
  .subTitle01 {
    text-align: left !important;
  }
  .deviceDivision01 {
    display: none !important;
    visibility: hidden !important;
  }
  .btnDivision01 {
    margin-top: -100px !important;
  }

  .title02 {
    text-align: left !important;
  }
}