.background {
  background-color: #0058D0 !important;
}

.textHeader li {
  justify-content: center;
  align-items: center;
  align-self: center;
}

.textHeader li > div {
  font-family: 'Poppins' !important;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
}

.menuToggler {
  font-family: 'Poppins' !important;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF !important;
  background-color: transparent !important;
  border-color: #FFFFFF !important;
}

.imageLogo {
  width: 143.08px;
  height: 60px;
  cursor: pointer;
}

.textLanguage {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
}

.imageLanguage {
  width: 32px;
  height: 32px;
}

.colorLinkActive {
  color: #FFCC00 !important;
}

.colorLinkDisable {
  color: #FFFFFF !important;
}