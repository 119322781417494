
.title01 {
  font-family: 'Poppins' !important;
  font-size: 28px;
  font-weight: 700;
  line-height: 42px;
  color: #0058D0;
  font-style: normal;
  text-align: center;
}

.section01 {
  background-color: #F5F5F5;
  height: 100vh;
}

.section02 {
  justify-content: center;
  display: flex;
  margin-top: 70px;
}

.container {
  width: 210px;
  height: 204px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  border: 1px solid #C4C4C4;
  border-radius: 4px;
  cursor: pointer;
}

.textContainer {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  color: #121212;
  text-align: center;
}

.containerForText {
  height: 50px;
  display: flex;
  align-items: center;
}

.divider {
  width: 24px;
}

.imageContainer {
  width: 60px;
  height: 60px;
  margin-bottom: 30px;
}