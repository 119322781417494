.backgroundColorPrimary {
  background-color: #BA0006;
}

.primary {
  background-color: #BA0006 !important;
  color: #FFFFFF !important;
}

.secondaryWhite {
  font-family: 'Poppins' !important;
  background-color: transparent !important;
  color: #FFFFFF !important;
  border-color: #FFFFFF !important;
}

.secondaryBlack {
  font-family: 'Poppins' !important;
  background-color: transparent !important;
  color: #121212 !important;
  border-color: #121212 !important;
}

.secondaryGray {
  font-family: 'Poppins' !important;
  background-color: #757575;
  color: #FFFFFF !important;
  border-color: #757575 !important;
}

.secondaryGray:hover {
  background-color: #757575;
}

.secondaryGray:active {
  background-color: #757575 !important;
}

.infoButton {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  border-radius: 8px !important;
  height: 48px;
}

.infoButtonImg {
  height: 48px !important;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  border-radius: 8px !important;
  display: flex;
  width: 115px;
  align-items: center;
  margin-bottom: 32px;
  img {
    margin-right: 12px;
  }
}

.iconImage {
  width: 16px;
  height: 16px;
}