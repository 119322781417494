.alice-carousel__prev-btn-item.__inactive, .alice-carousel__next-btn-item.__inactive {
  opacity: 0;
  pointer-events: none;
}

.alice-carousel__prev-btn-item {
  position: absolute;
  top: 45%;
  left: 2%;

  background-color: #0058D0;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  text-align: center;
}

.alice-carousel__next-btn-item {
  position: absolute;
  top: 45%;
  right: 2%;
  background-color: #0058D0;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  text-align: center;
}

.alice-carousel__next-btn-item, .alice-carousel__prev-btn-item > span {
  color: #FFCC00 !important;
}