@use '../../../Components/Theme/theme';

.title01 {
  font-family: theme.$main-font;
  font-size: 28px;
  font-weight: 700;
  line-height: 110%;
  color: #FFCC00;
  font-style: normal;
  margin-bottom: 24px;
  text-align: left;
}

.subTitle01 {
  font-family: theme.$main-font;
  font-size: 18px;
  font-weight: 600;
  line-height: 140%;
  font-style: normal;
  color: #FFFFFF !important;
  text-align: left;
  margin-bottom: 32px;
}

.section01 {
  background-color: #0058D0;
  height: 100vh;
}

.section02 {
  justify-content: center;
  display: flex;
  margin-bottom: 32px;
}

.section03 {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  margin-bottom: 32px;
}

.lineDivider {
  width: 50%;
  height: 1px;
  background-color: #FFFFFF;
}

.textDivider {
  padding: 6px;
  font-family: theme.$main-font;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
}

.section04 {
  display: flex;
  justify-content: center;
}

.textLabel {
  font-family: theme.$main-font;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  color: #FFFFFF;
}

.asterisk {
  color: #BA0006;
  margin-left: 5px;
}

.divider {
  width: 42px;
}

.imageSocialNetwork {
  width: 48px;
  height: 48px;
}

.container {
  width: 480px;
}