
.containerTimeLine {
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.contentTimeLine {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.circleText {
  width: 56px;
  margin-top: 4px;
  margin-right: 40px;
}

.circle {
  background-color: #757575;
  width: 40px;
  height: 40px;
  border-radius: 20px;
}

.circleChecked {
  background-color: #0058D0;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dividerTimeLine {
  width: 56px;
  height: 4px;
  background-color: #757575;
}

.dividerTimeLineBlue {
  width: 56px;
  height: 4px;
  background-color: #0058D0;
}

.textTimeLine {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 11px;
  color: #121212;
  text-align: center;
}

.iconCircle {
  width: 14px;
  height: 12px;
}

.wrapping {
  display: flex;
  align-items: center;
}