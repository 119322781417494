
.title01 {
  font-family: 'Poppins' !important;
  font-size: 28px;
  font-weight: 700;
  line-height: 31px;
  color: #0058D0;
  font-style: normal;
  text-align: left;
  margin-top: 24px;
}

.section01 {
  background-color: #F5F5F5;
  min-height: 100vh
}

.section02 {
  margin-top: 48px;
}

.textLabel {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #121212;
}

.asterisk {
  color: #BA0006;
  margin-left: 5px;
}

.formDropdown {
background-image: url("data:image/svg+xml;charset=utf-8,<svg width='12' height='8' fill='none' xmlns='http:%2F%2Fwww.w3.org/2000/svg'><path d='M1 1l4 4 4-4' stroke='blue' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/></svg>");
}

.containerPassport01 {
  border: 2px dashed #A6A6A6;
  padding: 10px;
}

.containerPassport02 {
  background-color: #FFFFFF;
  padding: 50px;
}

.textPassport01 {
  text-align: center;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #A6A6A6;
}

.textPassport02 {
  color: #BA0006;
  text-decoration-line: underline;
}

.addAnotherInstitution {
  justify-content: center;
  display: flex;
}

.firstDesc {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #121212;
}